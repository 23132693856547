import React, { Component, useState } from 'react';
import Slide from '../../Slides/Slide';
import "../../../static/css/default.css"
import kt1 from '../../../static/img/popup/KTCloud_popup.png'
import new2023 from '../../../static/img/popup/2023.png'
import Popout from 'react-popout'



const Home = () => {
  return (
    <>
      <Slide />
      {/*<NewWindow name='공지 테스트' title='공지 테스트' url={test_img}>
                </NewWindow>*/}
      {/*<NewWindow name='KT Cloud' title='KT Cloud' className='popup_1' url={kt1}>
                </NewWindow>*/}
      <Popout url={kt1} title='Vikcloud 무료 체험' options={{width: '500px', height: '707px', location: 'no', status: 'no', toolbar: 'no', scrollbars: 'no'}}/>
      {/*<Popout url={new2023} title='2023년 행복한 설 연휴 보내세요' options={{ width: '600px', height: '600px', location: 'no', status: 'no', toolbar: 'no', scrollbars: 'no' }} />*/}
    </>
  );
};

export default Home;
import React from 'react';
import "../../../static/css/default.css";
import "../../../static/css/years.css";
import kt_cloud_logo from '../../../static/img/ktCloudLogo.png';
import aws_logo from '../../../static/img/awsLogo.png';
import ds_logo from '../../../static/img/digitalservice.png';
import db_logo from '../../../static/img/thales.svg';
import ContentsHeader from '../../Layout/Contents_Header';
import Corning from '../../../static/img/Corning.png';
import Panduit from '../../../static/img/partner/panduit.png';
import cisco_icon from '../../../static/img/cisco/cisco.png'; 
import exfo_icon from '../../../static/img/EXPO/EXPO.png'; 
import viavi_icon from '../../../static/img/VIAVI/VIAVI.png'; 

const Years = () => {
    return (
        <>
            <ContentsHeader name="company"/>
            <br/>
            <br/>
            <body className='history_body'>
                <h1 style={{marginLeft: '5%'}}><b>연혁</b></h1>
                <br/>
                <br/>
                <div className="history" style={{marginLeft: '5%'}}>
                    <div>
                        <h2><b>2024년</b></h2>
                        <ol>
                        <img
                            src={cisco_icon}
                            alt="CISCO Icon"
                            width="64"  
                            style={{marginBottom: '0.1%', marginTop: '0.0001%'}}
                        />
                            <li><b>CISCO Select Partner 계약</b></li>
                        </ol>
                        <h2><b>2023년</b></h2>
                        <ol>
                        <img
                            src={exfo_icon}
                            alt="EXFO Icon"
                            width="64"
                            style={{marginBottom: '0.7%', marginTop: '0.5%'}}
                        />
                            <li><b>EXFO 파트너 계약</b></li>
                        <img
                            src={viavi_icon}
                            alt="VIAVI Icon"
                            width="64"
                            style={{marginBottom: '0.2%', marginTop: '0.5%'}}
                        />
                            <li><b>VIAVI 파트너 계약</b></li>
                        </ol>
                        <h2><b>2022년</b></h2>
                        <ol>
                            <li><b>통합배선 솔루션 파트너 계약</b></li>
                        </ol>
                        <h2><b>2018년</b></h2>
                        <ol>
                            <li><b>웨어밸리데이타 제휴 (DB접근제어, 망 이중화 솔루션)</b></li>
                        </ol>
                        <h2><b>2017년</b></h2>
                        <ol>
                            <li><b>SKB 네트워크 솔루션 협력사</b></li>
                        </ol>
                        <h2><b>2016년</b></h2>
                        <ol>
                            <li><b>보메트릭 제휴 (DB보안솔루션)</b></li>
                        </ol>
                        <h2><b>2015년</b></h2>
                        <ol>
                            <li><b>(주)주호시스템즈 설립</b></li>
                        </ol>
                    </div>
                </div>
            </body>
        </>
    );
};

export default Years;

import React from 'react';
import "../../../static/css/default.css"


const Privacy = () => {
    return (
        <>
            <div className='context'>
                <h2>
                    개인정보 처리방침
                </h2>
                <div className='solid_privacy'></div>
                본 방침은 (주)주호시스템즈 홈페이지에서 취급하는 개인정보와 기타 필요한 사항을 규정하는 것을 목적으로 합니다.<br/><br/>
                주호시스템즈 홈페이지 개인정보보호처리방침<br/><br/>
                주호시스템즈 홈페이지에서 처리하는 모든 개인정보는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 수집·보유·처리되고 있습니다.<br/>
                주호시스템즈 홈페이지는 개인정보보호법에 따라 이용자의 개인정보 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다.<br/>
                주호시스템즈 홈페이지는 개인정보처리방침을 변경하는 경우에는 시행의 시기, 변경된 내용을 정보주체가 쉽게 확인 할 수 있도록 변경 전·후를 비교하여 공개하도록 합니다.<br/><br/>
                <b>[개인정보의 처리목적]</b><br/>
                주호시스템즈 홈페이지는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br/>
                1. 서비스 제공 등 이용계약의 이행<br/>
                2. 마케팅 정보 생성 및 이용 고객별 안내<br/>
                3. 광고 전송 또는 우송 등<br/>
                4. 데이터센터/클라우드 QnA 등의 민원처리, 고지사항 전달 등<br/><br/>
                <b>[개인정보의 처리 및 보유기간]</b><br/>
                ① 주호시스템즈 홈페이지는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br/>
                ② 주호시스템즈 홈페이지는 서비스 이용을 위해 성명, 이메일 및 직무 등의 개인정보를 수집 및 처리합니다.<br/>
                ③ 주호시스템즈 홈페이지에서 수집 및 처리하는 개인정보의 보유기간은 서비스 이용기간(신청일～탈퇴일)으로 한정합니다.<br/><br/>
                <b>[개인정보처리자의 개인정보 수집에 대한 동의를 거부할 권리와 그에 따른 불이익 내용]</b><br/>
                정보주체는 주호시스템즈 홈페이지에서 수집하는 개인정보에 대해 동의를 거부할 권리가 있습니다.<br/><br/>
                <b>[개인정보의 제3자 제공]</b><br/>
                주호시스템즈 홈페이지는 원칙적으로 정보주체의 개인정보를 수집·이용 목적으로 명시한 범위 내에서 처리하며, 다음의 경우를 제외하고는 정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.<br/>
                1. 정보주체로부터 별도의 동의를 받는 경우<br/>
                2. 법률에 특별한 규정이 있는 경우<br/>
                3. 정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br/>
                4. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아 볼 수 없는 형태로 개인정보를 제공하는 경우<br/>
                5. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 개인정보보호위원회의 심의·의결을 거친 경우<br/>
                6. 조약, 그 밖의 국제협정의 이행을 위하여 외국정보 또는 국제기구에 제공하기 위하여 필요한 경우<br/>
                7. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우<br/>
                8. 법원의 재판업무 수행을 위하여 필요한 경우<br/>
                9. 형 및 감호, 보호처분의 집행을 위하여 필요한 경우<br/><br/>
                <b>[개인정보 처리 위탁]</b><br/>
                주호시스템즈 홈페이지는 개인정보의 처리업무를 위탁하는 경우 다음의 내용이 포함된 문서에 의하여 처리하고 있습니다.<br/>
                1. 위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항<br/>
                2. 개인정보의 관리적·기술적 보호조치에 관한 사항<br/>
                3. 개인정보의 안전관리에 관한 사항<br/>
                4. 위탁업무의 목적 및 범위, 재위탁 제한에 관한 사항, 개인정보 안전성 확보 조치에 관한 사항, 위탁업무와 관련하여 보유하고 있는 개인정보의 관리현황점검 등 감독에 관한 사항, 수탁자가 준수하여야할 의무를 위반한 경우의 손해배상책임에 관한 사항<br/><br/>
                <b>[정보주체의 권리·의무 및 그 행사 방법]</b><br/>
                정보주체는 다음과 같은 권리를 행사 할 수 있으며, 만14세 미만 아동의 법정대리인은 그 아동의 개인정보에 대한 열람, 정정·삭제, 처리정지를 요구할 수 있습니다.<br/>
                ① 개인정보 열람 요구<br/>
                주호시스템즈 홈페이지에서 보유하고 있는 개인정보파일은「개인정보보호법」제35조(개인정보의 열람)에 따라 자신의 개인정보에 대한 열람을 요구할 수 있습니다. 다만, 개인정보 열람 요구는 법 제35조 5항에 의하여 다음과 같이 제한될 수 있습니다.<br/>
                1. 법률에 따라 열람이 금지되거나 제한되는 경우<br/>
                2. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우<br/>
                ② 개인정보 정정·삭제 요구<br/>
                주호시스템즈 홈페이지에서 보유하고 있는 개인정보파일은「개인정보보호법」 제36조(개인정보의 정정·삭제)에 따라 정정·삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
                ③ 개인정보 처리정지 요구<br/>
                주호시스템즈 홈페이지에서 보유하고 있는 개인정보파일은 「개인정보보호법」 제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수 있습니다. 다만, 개인정보 처리정지 요구 시 법 제37조 2항에 의하여 처리정지 요구가 거절될 수 있습니다.<br/>
                1. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우<br/>
                2. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우<br/>
                3. 주호시스템즈 홈페이지에서 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우<br/>
                4. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우<br/><br/>
                <b>[개인정보의 파기]</b><br/>
                주호시스템즈 홈페이지는 원칙적으로 개인정보 처리목적이 달성된 개인정보는 지체없이 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br/>
                1. 파기 절차<br/>
                개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및 기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br/>
                2. 파기 기한 및 파기 방법<br/>
                보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등 그 개인정보가 불필요하게 되었을 때에는 지체없이 파기합니다. 전자적 파일형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/><br/>
                <b>[개인정보의 안전성 확보 조치]</b><br/>
                1. 개인정보 취급직원의 최소화 및 교육<br/>
                개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여 지정 · 관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을 실시하고 있습니다.<br/>
                2. 개인정보에 대한 접근 제한<br/>
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>
                3. 접속기록의 보관<br/>
                개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월 이상 보관·관리하고 있습니다.<br/>
                4. 개인정보의 암호화<br/>
                개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br/>
                5. 보안프로그램 설치 및 주기적 점검·갱신<br/>
                해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.<br/>
                6. 비인가자에 대한 출입 통제<br/>
                개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br/><br/>
                <b>[권익침해 구제 방법]</b><br/>
                개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.<br/>
                <b>개인정보 분쟁조정위원회</b> : (국번없이) 118 (privacy.kisa.or.kr)<br/>
                <b>개인정보 침해신고센터</b> : (국번없이) 118 (privacy.kisa.or.kr)<br/>
                <b>대검찰청 사이버범죄수사단</b> : 02-3480-3571(cybercid@spo.go.kr)<br/>
                <b>경찰청 사이버테러대응센터</b> : 1566-0112(www.netan.go.kr)<br/><br/>

                개인정보 보호책임자 : 주호시스템즈 서은희 이사<br/>
                전화번호 : 070-4190-8323<br/>
                이메일 : audit@vikcloud.net<br/><br/>
                개인정보처리방침의 적용<br/>
                1. 이 개인정보 처리방침은 2022년 1월 1일부터 적용됩니다.
            </div>
        </>
    );
};

export default Privacy;
